import { PATHS } from 'routes/paths';
import SvgColor from 'components/svg-color';
import Home from 'assets/icons/navbar/Home.svg';
import News from 'assets/icons/navbar/News.svg';
import Files from 'assets/icons/navbar/Files.svg';
import Regulations from 'assets/icons/navbar/Regulations.svg';
import Course from 'assets/icons/navbar/Course.svg';
import Gallery from 'assets/icons/navbar/Gallery.svg';
// import Employee from 'assets/icons/navbar/Employee.svg';
import Holiday from 'assets/icons/navbar/Holiday.svg';
import Jobs from 'assets/icons/navbar/Jobs.svg';
import Feedback from 'assets/icons/navbar/Feedback.svg';
import Expense from 'assets/icons/navbar/Expense.svg';
import Payment from 'assets/icons/navbar/Payment.svg';


const icon = (src) => <SvgColor src={src} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  home: icon(Home),
  news: icon(News),
  files: icon(Files),
  regulations: icon(Regulations),
  // erp: icon(Feedback),
  course: icon(Course),
  gallery: icon(Gallery),
  // employee: icon(Employee),
  holiday: icon(Holiday),
  expense: icon(Expense),
  payment: icon(Payment),
  jobs: icon(Jobs),
  feedback: icon(Feedback),
  asset: icon(Files)
};

export const navConfig = [
  {
    subheader: '',
    items: [
      { title: 'Нүүр хуудас', path: PATHS.home, icon: ICONS.home },
      { title: 'Мэдээ мэдээлэл', path: PATHS.news, icon: ICONS.news },
      { title: 'Хэрэгцээт файлууд', path: PATHS.files, icon: ICONS.files },
      { title: 'Дүрэм, журам', path: PATHS.regulations, icon: ICONS.regulations },
      { title: 'Сургалт', path: PATHS.course, icon: ICONS.course },
      { title: 'Зургийн цомог', path: PATHS.gallery, icon: ICONS.gallery },
      // { title: 'Ажилчдын мэдээлэл', path: PATHS.employees, icon: ICONS.employee },
      { title: 'Амралт чөлөө', path: PATHS.holiday, icon: ICONS.holiday },
      { title: 'Хөрөнгө', path: PATHS.asset, icon: ICONS.asset},
      // { 
      //   title: 'Амралт чөлөө',
      //   icon: ICONS.holiday, 
      //     children: [
      //       { title: 'Хүсэлт илгээх', path: PATHS.all_holiday },
      //       { title: 'Хүсэлтийн жагсаалт', path: PATHS.holiday},
      //     ],
      // },
      { title: 'БМ-ын шаардах', path: PATHS.expense, icon: ICONS.expense },
      { title: 'Төлбөрийн хүсэлт', path: PATHS.payment, icon: ICONS.payment },
      { title: 'Нээлттэй ажлын байр', path: PATHS.jobs, icon: ICONS.jobs },
      { title: 'KPI', path: PATHS.kpi, icon: ICONS.files,
        children: [
          { title: 'ХХХөгжил төлөвлөгөө', path: PATHS.kpi, },
          { title: 'Хувь хүний KPI төлөвлөгөө тайлан', path: PATHS.kpi_personal},
        ],
       },
      { title: 'Санал хүсэлт', path: PATHS.feedback, icon: ICONS.feedback },
    ],
  },
];