import { Suspense, lazy } from 'react';
import LoadingScreen from 'components/loading-screen';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ##################################### IMPORT AUTH PAGES ###########################################
export const LoginPage = Loadable(lazy(() => import('pages/auth/login')));
export const LoginPasswordPage = Loadable(lazy(() => import('pages/auth/login/LoginPasswordPage')));
export const RegisterPage = Loadable(lazy(() => import('pages/auth/register')));
export const CreateRegistrationPage = Loadable(
  lazy(() => import('pages/auth/register/CreateRegistration'))
);
export const ForgotPasswordPage = Loadable(lazy(() => import('pages/auth/forgot-password')));
export const NewPasswordPage = Loadable(
  lazy(() => import('pages/auth/forgot-password/NewPasswordPage'))
);
export const VerifyCodePage = Loadable(lazy(() => import('pages/auth/verify-code')));

// ##################################### IMPORT MAIN PAGES ###########################################
export const ProfilePage = Loadable(lazy(() => import('pages/user/profile')));
export const HomePage = Loadable(lazy(() => import('pages/home')));
export const NewsPage = Loadable(lazy(() => import('pages/news')));
export const FilesPage = Loadable(lazy(() => import('pages/files')));
export const RegulationsPage = Loadable(lazy(() => import('pages/regulations')));
export const CoursePage = Loadable(lazy(() => import('pages/course')));
export const CourseExamPage = Loadable(lazy(() => import('pages/course/CourseExamPage')));
export const GalleryPage = Loadable(lazy(() => import('pages/gallery')));
export const EmployeesPage = Loadable(lazy(() => import('pages/employees')));
export const HolidayPage = Loadable(lazy(() => import('pages/holiday')));
export const AssetPage = Loadable(lazy(() => import('pages/asset')));
export const LeaveDetailInfo = Loadable(lazy(() => import('pages/holiday/leave_detail')));

// export const AllHolidayPage = Loadable(lazy(() => import('pages/holiday/all_holiday')));
// export const HolidayUpdatePage = Loadable(lazy(() => import('pages/holiday/updateHoliday')));
export const ExpensePage = Loadable(lazy(() => import('pages/expense')));
export const UpdateListPage = Loadable(lazy(() => import('pages/expense/updateList')));
export const CreateListPage = Loadable(lazy(() => import('pages/expense/createList')));
export const PaymentPage = Loadable(lazy(() => import('pages/payment')));
export const CreatePaymentPage = Loadable(lazy(() => import('pages/payment/create_payment')));
export const UpdatePaymentPage = Loadable(lazy(() => import('pages/payment/update_payment')));
export const JobsPage = Loadable(lazy(() => import('pages/jobs')));
export const FeedbackPage = Loadable(lazy(() => import('pages/feedback')));
export const KpiPage = Loadable(lazy(() => import('pages/kpi')));
export const KpiPlanDetail = Loadable(lazy(() => import('pages/kpi/kpi_plan_detail')));
export const KpiPersonal = Loadable(lazy(() => import('pages/kpi/kpi_personal')));
export const KpiPersonalDetail = Loadable(lazy(() => import('pages/kpi/kpi_personal_detail')));
// ##################################### IMPORT ERROR PAGES ###########################################
export const ComingSoonPage = Loadable(lazy(() => import('pages/error/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('pages/error/MaintenancePage')));
export const Page403 = Loadable(lazy(() => import('pages/error/Page403')));
export const Page404 = Loadable(lazy(() => import('pages/error/Page404')));
export const Page500 = Loadable(lazy(() => import('pages/error/Page500')));
