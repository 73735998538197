import { Navigate, useRoutes } from 'react-router-dom';
import AuthGuard from 'auth/AuthGuard';
import { CompactLayout, LoginLayout, MainLayout } from 'layouts';

import {
  // ############### IMPORT AUTH PAGES ###############
  LoginPage,
  LoginPasswordPage,
  RegisterPage,
  CreateRegistrationPage,
  VerifyCodePage,
  NewPasswordPage,
  ForgotPasswordPage,
  // ############### IMPORT MAIN PAGES ###############
  ProfilePage,
  HomePage,
  NewsPage,
  FilesPage,
  RegulationsPage,
  CoursePage,
  CourseExamPage,
  GalleryPage,
  EmployeesPage,
  // LeaveList,
  HolidayPage,
  LeaveDetailInfo,
  // AllHolidayPage,
  // HolidayUpdatePage,
  ExpensePage,
  UpdateListPage,
  CreateListPage,
  PaymentPage,
  CreatePaymentPage,
  UpdatePaymentPage,
  JobsPage,
  FeedbackPage,
  KpiPage,
  KpiPlanDetail,
  KpiPersonal,
  KpiPersonalDetail, 
  AssetPage,
  // ############### IMPORT ERROR PAGES ###############
  ComingSoonPage,
  MaintenancePage,
  Page403,
  Page404,
  Page500,
} from './elements';
// import LeaveList from 'pages/holiday/list';

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard>
          <LoginLayout />
        </AuthGuard>
      ),
    },
    // ######################## AUTH PAGE ROUTES ########################
    {
      path: 'auth',
      element: (
        <AuthGuard>
          <LoginLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'login',
          children: [
            {
              path: 'email',
              element: <LoginPage />,
            },
            {
              path: 'password/:email/:type/:registered/:employeeId',
              element: <LoginPasswordPage />,
            },
          ],
        },
        {
          path: 'register',
          children: [
            {
              path: 'check',
              element: <RegisterPage />,
            },
            {
              path: 'create-registration',
              element: <CreateRegistrationPage />,
            },
          ],
        },
        { path: 'forgot-password', element: <ForgotPasswordPage /> },
        { path: 'new-password', element: <NewPasswordPage /> },
        { path: 'verify/:phone/:type', element: <VerifyCodePage /> },
      ],
    },

    // ######################## MAIN PAGE ROUTES ########################
    {
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'home', element: <HomePage /> },
        { path: 'news', element: <NewsPage /> },
        { path: 'files', element: <FilesPage /> },
        { path: 'regulations', element: <RegulationsPage /> },
        { path: 'gallery', element: <GalleryPage /> },
        { path: 'employees/:searchValue', element: <EmployeesPage /> },
        { path: 'employees/', element: <EmployeesPage /> },
        { 
          path: 'holiday',
          children: [
            { element: <Navigate to="/holiday/list" replace />, index: true },
            { path: 'list', element: <HolidayPage /> },
            { path: 'leave_info', element: <LeaveDetailInfo /> },
          ],
        }, 


        // { 
        //   path: 'holiday',
        //   element: <HolidayPage />,
        //   children: [
        //     { element: <Navigate to="/holiday" replace />, index: true },
        //     { path: 'all_holiday', element: <AllHolidayPage /> },
        //     { path: 'update/holiday', element: <HolidayUpdatePage /> },
        //   ],
        // },
        // { path: 'all_holiday', element: <AllHolidayPage />},
        // { path: 'update/holiday', element: <HolidayUpdatePage />},
        { 
          path: 'expense',
          children: [
            { element: <Navigate to="/expense/list" replace />, index: true },
            { path: 'list', element: <ExpensePage /> },
            { path: 'updateList', element: <UpdateListPage /> },
            { path: 'createList', element: <CreateListPage /> },
          ],
        },
        { 
          path: 'payment',
          children: [
            { element: <Navigate to="/payment/list" replace />, index: true },
            { path: 'list', element: <PaymentPage /> },
            { path: 'create_payment', element: <CreatePaymentPage /> },
            { path: 'update_payment', element: <UpdatePaymentPage /> },
          ],
        },
        { 
          path: 'kpi',
          children: [
            { element: <Navigate to="/kpi/list" replace />, index: true },
            { path: 'list', element: <KpiPage /> },
            { path: 'kpi_plan_detail', element: <KpiPlanDetail /> },
            { path: 'kpi_personal', element: <KpiPersonal /> },
            { path: 'kpi_personal_detail', element: <KpiPersonalDetail /> },

          ],
        },
        { path: 'asset', element: <AssetPage /> },
        { path: 'jobs', element: <JobsPage /> },
        { path: 'feedback', element: <FeedbackPage /> },
        {
          path: 'course',
          children: [
            { element: <Navigate to="/course/list" replace />, index: true },
            { path: 'list', element: <CoursePage /> },
            { path: 'lesson/detail', element: <CoursePage /> },
            { path: 'exam', element: <CourseExamPage /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/user/profile" replace />, index: true },
            { path: 'profile', element: <ProfilePage /> },
          ],
        },
      ],
    },
    {
      element: (
        <AuthGuard>
          <CompactLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '403', element: <Page403 /> },
        { path: '404', element: <Page404 /> },
        { path: '500', element: <Page500 /> },
      ],
    },
  ]);
}
